<template>
  <section class="space-y-10">
    <QuickInfo />
    <offline-action-alert v-if="!isOnline" />
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card>
      <section class="space-y-4">
        <asom-form-field :label="'From'" class="">
          <p class="mt-3 text-sm text-gray-500">AFC</p>
        </asom-form-field>
        <asom-form-field
          :label="'To'"
          class=""
          :state="inputStates('formData.gtmId')"
          error="Please select a GTM/TUK"
        >
          <asom-input-radio-group
            class="pt-3"
            horizontal
            v-model="formData.gtmId"
            :options="gtmOptions"
            :state="inputStates('formData.gtmId')"
          />
        </asom-form-field>
        <denominations
          :label="'Order Amount'"
          :state="inputStates('formData.totalNoOfTubes')"
          error="Total no of tubes should be non-zero."
          show-remarks
          show-coins-per-tube
          v-model:noOf10CentTubes="formData.noOf10CentTubes"
          v-model:noOf20CentTubes="formData.noOf20CentTubes"
          v-model:noOf50CentTubes="formData.noOf50CentTubes"
          v-model:noOf1DollarTubes="formData.noOf1DollarTubes"
          v-model:remarks="formData.remarks"
        />
      </section>
      <template #footer>
        <div class="flex justify-between flex-1">
          <asom-button
            variant="link"
            text="Cancel"
            @click="$router.push({ name: 'Cointube Main' })"
          />
          <asom-button text="Submit" @click="onSubmitBtnClicked" />
        </div>
      </template>
    </asom-card>
    <asom-modal title="Confirm" v-model="showModal" :dismissible="false">
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="showModal = false"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric, minValue } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import QuickInfo from "../../_QuickInfo.vue";
import Denominations from "../../_Denominations.vue";
import { useCoinTube } from "../../../../services/cashManagement.service";
import OfflineActionAlert from "@/layout/OfflineActionAlert";

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    QuickInfo,
    Denominations,
    OfflineActionAlert,
  },
  data() {
    return {
      showModal: false,
      isSubmitting: false,
      error: null,
      formData: {
        gtmId: null,
        noOf10CentTubes: 0,
        noOf20CentTubes: 0,
        noOf50CentTubes: 0,
        noOf1DollarTubes: 0,
        totalNoOfTubes: 0,
        remarks: "",
      },
    };
  },
  watch: {
    "formData.noOf10CentTubes": function() {
      this.updateTotalNoOfTubes();
    },
    "formData.noOf20CentTubes": function() {
      this.updateTotalNoOfTubes();
    },
    "formData.noOf50CentTubes": function() {
      this.updateTotalNoOfTubes();
    },
    "formData.noOf1DollarTubes": function() {
      this.updateTotalNoOfTubes();
    },
  },
  validations() {
    return {
      formData: {
        gtmId: {
          required,
        },
        noOf10CentTubes: {
          numeric,
          minValue: minValue(0),
        },
        noOf20CentTubes: {
          numeric,
          minValue: minValue(0),
        },
        noOf50CentTubes: {
          numeric,
          minValue: minValue(0),
        },
        noOf1DollarTubes: {
          numeric,
          minValue: minValue(0),
        },
        totalNoOfTubes: {
          numeric,
          minValue: minValue(1),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      gtmOptions: "cashManagement/stationCashAccounts/gtmOptions",
      coinTubeConfiguration:
        "cashManagement/configuration/coinTubeConfiguration",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
      isOnline: "apiStatus/isCMModuleOnline",
    }),
    submissionData() {
      return {
        AFCId: this.afcId,
        GTMId: this.formData.gtmId,
        noOf10CentTubes: this.formData.noOf10CentTubes,
        noOf20CentTubes: this.formData.noOf20CentTubes,
        noOf50CentTubes: this.formData.noOf50CentTubes,
        noOf1DollarTubes: this.formData.noOf1DollarTubes,
        noOf10CentsPerTube: this.coinTubeConfiguration.numberOf10CentsPerTube,
        noOf20CentsPerTube: this.coinTubeConfiguration.numberOf20CentsPerTube,
        noOf50CentsPerTube: this.coinTubeConfiguration.numberOf50CentsPerTube,
        noOf1DollarsPerTube: this.coinTubeConfiguration.numberOf1DollarsPerTube,
        remarks: this.formData.remarks,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      };
    },
  },
  methods: {
    updateTotalNoOfTubes() {
      this.formData.totalNoOfTubes =
        this.formData.noOf10CentTubes +
        this.formData.noOf20CentTubes +
        this.formData.noOf50CentTubes +
        this.formData.noOf1DollarTubes;
    },
    onSubmitBtnClicked() {
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.error = "Please completed all required fields.";
        this.$scrollTop();
        return;
      } else {
        this.showModal = true;
      }
    },
    onSubmit() {
      if (this.isOnline) {
        this.useCoinTubeAsync();
      } else {
        this.$store.dispatch("offlineData/useCoinTube", this.submissionData);
        this.showModal = false;
        this.$router.push({ name: "Cointube Main" });
      }
    },
    async useCoinTubeAsync() {
      this.error = null;
      this.isSubmitting = true;
      const result = await useCoinTube(this.submissionData);
      this.isSubmitting = false;
      if (!result.success) {
        this.error = result.payload;
        this.showModal = false;
        this.$scrollTop();
      } else {
        this.$router.push({ name: "Cointube Main" });
      }
    },
  },
};
</script>
